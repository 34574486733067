import { load } from 'recaptcha-v3'
import { sendRequest } from './Axios'
import { verifyRecaptchaUrl } from '../config/api'

const siteKey = process.env.GATSBY_RECAPTCHA_SITE_KEY || 'RECAPTCHA_KEY'
const renderParameters = {
  badge: 'inline',
  size: 'invisible',
}

export const validateToken = (token: string): Promise<boolean> =>
  new Promise(async (resolve, reject) => {
    try {
      const data = `token=${token}`
      const { success } = await sendRequest(verifyRecaptchaUrl, 'POST', data)
      resolve(success)
    } catch (error) {
      reject(error)
    }
  })

export const getRecaptchaToken = (): Promise<boolean> =>
  new Promise(async (resolve, reject) => {
    try {
      const recaptcha = await load(siteKey, {
        autoHideBadge: true,
        renderParameters,
      })

      const token = await recaptcha.execute('contact')
      const validated = await validateToken(token)

      resolve(validated)
    } catch (error) {
      reject(error)
    }
  })
