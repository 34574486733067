/**
 * Api base
 */
const apiBase = process.env.GATSBY_MAIL_API_BASE

// Dev testing
// apiBase = "http://localhost:10000"

/**
 * Contact form url
 */
export const contactUrl = `${apiBase}/api/mail`

/**
 * Verify Recaptcha token url
 */
export const verifyRecaptchaUrl = `${apiBase}/api/mail/verify`
