import React, { useState, useEffect } from 'react'
import SocialLinks from '@partials/SocialLinks'
import Button from '@button'
import Image from '@partials/Image'
import { contactUrl } from '@api'
import { sendRequest, getQueryString } from '@helpers/Axios'
import { presentToast } from '@helpers/Toast'
import { getRecaptchaToken } from '@helpers/Recaptcha'
import { ImageObject } from 'types'

import mail from '@images/mail.svg'

type Props = {
  image: ImageObject
}

const ContactForm: React.FC<Props> = ({ image }) => {
  const [formData, setFormData] = useState({
    to: process.env.GATSBY_MAIL_RECIPIENT ?? '',
  })
  const [isValidated, setValidated] = useState(false)

  useEffect(() => {
    const getToken = async (): Promise<void> => {
      const errorMsg = "It seems that you're a robot, try again later."

      try {
        const token = await getRecaptchaToken()
        setValidated(token)

        if (!token) await presentToast('error', 'top-right', errorMsg)
      } catch (error) {
        await presentToast('error', 'top-right', errorMsg)
      }
    }

    getToken()
  }, [])

  const handleChange = (e: React.FormEvent<HTMLInputElement>): void => {
    const { name, value } = e.currentTarget

    setFormData((prevState) => ({ ...prevState, [name]: value }))
  }

  const inputProps = (name: string, placeholder: string, isRequired: boolean): object => ({
    type: 'text',
    name,
    placeholder,
    required: isRequired,
    onChange: handleChange,
  })

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()
    const form = e.currentTarget
    const button = form.querySelector('button')

    try {
      const data = getQueryString(formData)

      if (button) {
        button.childNodes[0].textContent = 'Sending'
        button.classList.add('is-loading')
      }

      await sendRequest(contactUrl, 'POST', data)

      form.reset()
      await presentToast('success', 'top-right', 'Email sent successfully')
    } catch (error) {
      await presentToast('error', 'top-right', "There's been an error, please try again later")
    } finally {
      if (button) {
        button.classList.remove('is-loading')
        button.childNodes[0].textContent = 'Send'
      }
    }
  }

  return (
    <div className="columns is-multiline contact-page">
      <div className="column is-5">
        <Image animate fadeType="fadeIn" wowDuration={1.5} src={image.url} alt={image.alt} />
      </div>
      <div className="column is-1" />
      <div className="column is-6 contact-form-wrapper">
        <div className="contact-form-info wow fadeIn" data-wow-delay="0.5s" data-wow-duration="1.5s">
          <a className="mail" href="mailto:eikeryejan@gmail.com" target="_blank" rel="noopener noreferrer">
            <img src={mail} alt="mail-icon" />
            eikeryejan@gmail.com
          </a>
          <SocialLinks />
        </div>
        <form className="wow fadeIn" data-wow-delay="0.5s" data-wow-duration="1.5s" onSubmit={handleSubmit}>
          <input {...inputProps('name', 'Name', true)} />
          <input {...inputProps('phone', 'Phone', false)} />
          <input {...inputProps('email', 'Email', true)} />
          <input {...inputProps('subject', 'Subject', true)} />
          <textarea {...inputProps('message', 'Message', true)} />
          <Button elType="submit" className={`has-arrow ${isValidated ? 'is--allowed' : 'is--disabled'}`}>
            Send
          </Button>
        </form>
      </div>
    </div>
  )
}

export default ContactForm
