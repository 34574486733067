import React from 'react'
import { graphql } from 'gatsby'
import Seo from '@seo'
import PageHeader from '@pageHeader'
import Footer from '@footer'
import { PageProps } from 'types'
import ContactForm from '../components/ContactForm'
import '@sass/pages/Contact.scss'

const Contact = ({ data }: PageProps): JSX.Element => {
  const { pageTitle, pageDescription, pageColor, titleFirst, titleSecond, excerptFirst, excerptSecond, template } =
    data.contentfulPage
  const { formImage } = template

  return (
    <>
      <Seo title={pageTitle} themeColor={pageColor} description={pageDescription} />
      <PageHeader
        color={pageColor}
        title={{
          first: titleFirst,
          second: titleSecond,
        }}
        excerpt={{
          first: excerptFirst,
          second: excerptSecond,
        }}
      />
      <div className="column is-12 page">
        <ContactForm
          image={{
            url: formImage.file.url,
            alt: 'contact-form',
          }}
        />
      </div>
      <Footer />
    </>
  )
}

export default Contact

export const query = graphql`
  query ContactPage($contactPageId: String!) {
    contentfulPage(contentful_id: { eq: $contactPageId }) {
      id
      pageColor
      pageTitle
      pageDescription
      titleFirst
      titleSecond
      excerptFirst
      excerptSecond
      template {
        ... on ContentfulTemplateContact {
          id
          formImage {
            file {
              url
            }
          }
        }
      }
    }
  }
`
