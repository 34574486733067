import axios, { Method } from 'axios'

export const sendRequest = async (url: string, method: Method, requresData: object | string) => {
  const response = await axios({
    url,
    method,
    data: requresData,
    headers: {
      'Content-type': 'application/x-www-form-urlencoded',
    },
  })

  return response.data
}

export const getQueryString = (object: { [key: string]: string }) => {
  const queryString = Object.keys(object)
    .map((key) => `${key}=${object[key]}`)
    .join('&')

  return queryString
}
