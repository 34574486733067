import cogoToast from 'cogo-toast'

/**
 *
 * PRESENT TOAST POPUP
 * @param type - Toast type
 * @param pos - Toast position
 * @param message - Toast message
 */
export const presentToast = async (
  type: 'error' | 'success',
  pos: 'top-right' | 'top-left',
  message: string,
): Promise<void> => {
  const options = {
    position: pos,
    heading: 'Share Pic says:',
    hideAfter: 1.5,
  }

  await cogoToast[type](message, options)
}
